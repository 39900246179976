@import 'tailwindcss/base';
@import 'tailwindcss/components';
@import 'tailwindcss/utilities';

@import url("https://unpkg.com/tippy.js@6/dist/tippy.css");
// @import url("https://unpkg.com/tippy.js@6/themes/dark.css" );


.tippy-box[data-theme~=light]{color:#26323d;box-shadow:0 0 20px 4px rgba(154,161,177,.15),0 4px 80px -8px rgba(36,40,47,.25),0 4px 4px -2px rgba(91,94,105,.15);background-color:#fff}.tippy-box[data-theme~=light][data-placement^=top]>.tippy-arrow:before{border-top-color:#fff}.tippy-box[data-theme~=light][data-placement^=bottom]>.tippy-arrow:before{border-bottom-color:#fff}.tippy-box[data-theme~=light][data-placement^=left]>.tippy-arrow:before{border-left-color:#fff}.tippy-box[data-theme~=light][data-placement^=right]>.tippy-arrow:before{border-right-color:#fff}.tippy-box[data-theme~=light]>.tippy-backdrop{background-color:#fff}.tippy-box[data-theme~=light]>.tippy-svg-arrow{fill:#fff}

html, body {
	font-size: 13px;
	height: 100%;
	width: 100%;
}

/* tippy.js */
.tippy-box .tippy-content {
	font-size: smaller !important;
}

.tippy-tooltip[data-animation=shift-away][data-placement^=top][data-state=hidden]{transform:translateY(10px)}.tippy-tooltip[data-animation=shift-away][data-placement^=bottom][data-state=hidden]{transform:translateY(-10px)}.tippy-tooltip[data-animation=shift-away][data-placement^=left][data-state=hidden]{transform:translateX(10px)}.tippy-tooltip[data-animation=shift-away][data-placement^=right][data-state=hidden]{transform:translateX(-10px)}.tippy-tooltip[data-animation=shift-away][data-state=hidden]{opacity:0}